import React, { useEffect } from 'react';
import { MediumRoundedButton } from '../atoms/Button';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const PaginationContainerNumbered = styled.div`
  margin-top: -1em;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 0.5em;
`;

type PaginationProps = {
  page: number;
  totalPages: number;
  onSelect: (page: number) => void;
};

export function Pagination({ page, totalPages, onSelect }: PaginationProps) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (totalPages <= 1) return null;

  const handlePageSelect = (newPage: number) => {
    return () => onSelect(newPage);
  };

  const hasPreviousPage = page > 1;
  const hasNextPage = page < totalPages;

  return (
    <PaginationContainerNumbered>
      {hasPreviousPage ? (
        <MediumRoundedButton onClick={handlePageSelect(page - 1)}>
          {t('Previous')}
        </MediumRoundedButton>
      ) : (
        <MediumRoundedButton as="button" disabled>
          {t('Previous')}
        </MediumRoundedButton>
      )}
      {hasPreviousPage && page > 2 && (
        <MediumRoundedButton onClick={handlePageSelect(1)}>
          1
        </MediumRoundedButton>
      )}
      {hasPreviousPage && page > 3 && (
        <MediumRoundedButton as="button" disabled>
          ...
        </MediumRoundedButton>
      )}
      {hasPreviousPage && (
        <MediumRoundedButton onClick={handlePageSelect(page - 1)}>
          {page >= 2 ? `${page - 1}` : ``}
        </MediumRoundedButton>
      )}
      <MediumRoundedButton
        style={{ background: '#e9e9e9' }}
        onClick={handlePageSelect(page)}
      >
        {page}
      </MediumRoundedButton>
      {hasNextPage && (
        <MediumRoundedButton onClick={handlePageSelect(page + 1)}>
          {`${page + 1}`}
        </MediumRoundedButton>
      )}
      {hasNextPage && page < totalPages - 2 && (
        <MediumRoundedButton as="button" disabled>
          ...
        </MediumRoundedButton>
      )}
      {hasNextPage && page < totalPages - 1 && (
        <MediumRoundedButton onClick={handlePageSelect(totalPages)}>
          {totalPages}
        </MediumRoundedButton>
      )}
      {hasNextPage ? (
        <MediumRoundedButton onClick={handlePageSelect(page + 1)}>
          {t('Next')}
        </MediumRoundedButton>
      ) : (
        <MediumRoundedButton as="button" disabled>
          {t('Next')}
        </MediumRoundedButton>
      )}
    </PaginationContainerNumbered>
  );
}
